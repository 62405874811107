<template>
  <BaseBonusPanel
    v-if="!isExpired"
    :id="bonus.id"
    :type="bonus.type"
    :timer="bonusTimer"
    :progress
  >
    <template #title>
      {{ title }}
    </template>
    <template v-if="!progress" #subtitle>
      <p
        v-if="percentAmount && isZeroAmount"
        class="subtitle percent"
        data-t="subtitle"
      >
        <span>{{ percentAmount }}</span>
      </p>
      <p
        v-else
        class="subtitle"
        :class="{ success: !isZeroAmount }"
        data-t="subtitle"
      >
        <span>{{ bonusAmount }}</span>
        <StIcon :name="currencyIcon" :size="14" />
      </p>
    </template>

    <template v-if="progress" #minmax>
      <div class="progress-data">
        <span class="progress-data-text">
          {{ t('bonuses.cashbackBetsLeft') }}
        </span>
        <span class="current-progress" data-t="current-progress">
          <span class="current-progress-text">{{ minMaxProgressText }}</span>
          <StIcon :name="currencyIcon" :size="14" />
        </span>
      </div>
    </template>
    <template v-if="!progress" #actions>
      <StButton
        size="m"
        class="button"
        :type="bonus.status === 'readyToClaim' ? 'primary' : 'ghost'"
        :loading="claimStatus === 'pending'"
        :disabled="isButtonDisabled"
        @click="onBonusSubmit"
      >
        {{ buttonTitle }}
        <ClientOnly>
          <StLottie
            v-if="claimStatus === 'success'"
            class="animation"
            :animation-data="claimAnimation"
          />
        </ClientOnly>
      </StButton>
    </template>
  </BaseBonusPanel>
</template>

<script setup lang="ts">
import { delay } from '@st/utils'
import BaseBonusPanel from './BaseBonusPanel.vue'
import { useRegularBonus } from '../../RegularBonusCard/useRegularBonus'
import type { RegularBonus } from '../../../types'
import { useGetTitleByLevel } from '../../../composables/useGetTitleByLevel'

interface Props {
  bonus: RegularBonus
}

const { bonus } = defineProps<Props>()
const { t } = useI18n()

const emit = defineEmits<{
  (e: 'claim'): void
}>()

const {
  handleBonusButtonClick,
  claimStatus,
  currencyIcon,
  bonusAmount,
  claimAnimation,
  bonusTimer,
  isExpired,
  percentAmount,
  progress,
  minMaxProgressText,
  isButtonDisabled,
  isZeroAmount,
} = useRegularBonus(computed(() => bonus))

async function onBonusSubmit() {
  await handleBonusButtonClick()
  /* для проигрыша анимации */
  await delay(500)
  emit('claim')
}

const { getTitle } = useGetTitleByLevel()
const title = computed(() => {
  switch (bonus.type) {
    case 'cashback':
      return t('bonuses.weeklyCashback')
    case 'rakeBack':
      return t('bonuses.rakeback')
    case 'reload':
      return getTitle(t('bonuses.reload'), bonus.level)
    case 'ratRace':
      return t('bonuses.ratRace')
    default:
      return ''
  }
})

const buttonTitle = computed(() => {
  if (bonus.type === 'reload') {
    return t('bonuses.take')
  }

  if (bonus.type === 'cashback') {
    return isZeroAmount.value && bonus.status === 'waitingForAccrual'
      ? t('bonuses.placeBets')
      : t('bonuses.take')
  }

  switch (bonus.status) {
    case 'readyToClaim':
      return t('bonuses.take')
    case 'waitingForAccrual':
      return t('bonuses.makeBets')
    default:
      return t('bonuses.goToGame')
  }
})
</script>

<style scoped>
.title {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
}

.subtitle {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  margin: 0;

  font: var(--mobile-caption-1-medium);
  color: var(--text-secondary);

  &.success,
  &.percent {
    color: var(--text-warning);
  }
}

.countdown {
  min-width: 60px;
}

.button {
  position: relative;
}

.animation {
  pointer-events: none;
  position: absolute;
  top: -12px;
  overflow: visible;
}

.progress-data {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  font: var(--mobile-caption-1-regular);
  color: var(--text-primary);
}

.progress-data-text {
  color: var(--text-secondary);
}

.current-progress {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;
}

.current-progress-text {
  overflow: hidden;
  max-width: 145px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
