<template>
  <BaseBonusPanel
    :id="freebet.id"
    type="freebet"
    :progress="progress"
    :steps-progress="stepsProgress"
    :paused="isRollingStatusNewOrPaused"
    :timer="bonusTimer"
    :data-t="`freebet-panel-${status}`"
  >
    <template #title> {{ title }} </template>
    <template #wager>
      <div v-if="isEnabledStepsRolling" class="steps-amount">
        <div
          v-if="unlockedAmount"
          class="steps-amount-info"
          data-t="unlocked-amount"
        >
          {{ t('bonuses.unlockedAmount') }}
          {{ format(unlockedAmount) }}
          <StIcon :name="freebet.currencyIcon" :size="14" />
        </div>
        <div v-else class="steps-amount-info" data-t="until-next-claim">
          {{ t('bonuses.untilNextClaim') }}
          {{ format(untilNextClaimAmount) }}
          <StIcon :name="freebet.currencyIcon" :size="14" />
        </div>
      </div>
      <span
        v-else-if="status === 'rolling' && isRollingStatusInProgress"
        class="wager"
        data-t="wager"
      >
        {{ t('bonuses.wager', { multiplier: wager }) }}
      </span>
    </template>
    <template #subtitle>
      <p v-if="isVisibleSubTitle" class="subtitle" data-t="subtitle">
        {{ freebetAmount }}
        <StIcon :name="freebet.currencyIcon" :size="14" />
      </p>
    </template>
    <template #minmax>
      <div
        v-if="status === 'rolling'"
        class="rolling-min-max"
        data-t="rolling-min-max"
      >
        <span v-if="isRollingStatusInProgress" class="rolling-text">
          <span class="rolling-text-amounts">
            {{ minMaxRollingTextWithoutCurrency }}
          </span>
          <StIcon :name="freebet.currencyIcon" :size="14" />
        </span>

        <span
          class="bonus-amount"
          :class="{ paused: isRollingStatusNewOrPaused }"
          data-t="bonus-amount"
        >
          <img :src="giftImage" alt="gift" width="20" />
          <span>
            {{ freebetAmount }}
          </span>
          <StIcon :name="freebet.currencyIcon" :size="14" />
        </span>
      </div>
    </template>
    <template #actions>
      <div
        v-if="isRollingStatusNewOrPaused || buttonSettings"
        class="actions-wrapper"
      >
        <StButton
          v-if="isRollingStatusNewOrPaused"
          size="m"
          type="secondary"
          :label="t('bonuses.toRoll')"
          @click="setCurrentRolling"
        />
        <StButton
          v-else-if="buttonSettings"
          class="button-settings"
          size="m"
          data-t="action-button"
          v-bind="buttonSettings"
          @click="buttonSettings.click"
        />
        <ClientOnly>
          <StLottie
            v-if="partClaimStatus === 'success'"
            class="animation"
            :animation-data="claimAnimation"
          />
        </ClientOnly>
      </div>
    </template>
  </BaseBonusPanel>
</template>

<script setup lang="ts">
import { delay } from '@st/utils'
import BaseBonusPanel from './BaseBonusPanel.vue'
import type { Freebet } from '../../../types'
import giftImage from '../assets/gift.png'
import { useFreebet } from '../../../composables/useFreebet'
import { useGetTitleByLevel } from '../../../composables/useGetTitleByLevel'
import { useBonusStepRolling } from '../../../composables/useBonusStepRolling'
import claimAnimation from '../../../assets/claim-animation.json'

interface Props {
  freebet: Freebet
}

const props = defineProps<Props>()

const { t } = useI18n()
const { freebet } = toRefs(props)

const {
  minMaxRollingTextWithoutCurrency,
  wager,
  status,
  progress,
  isRollingStatusNewOrPaused,
  setCurrentRolling,
  isRollingStatusInProgress,
  currentExpiredDate,
  timerTitle,
  fetchFreebets,
} = useFreebet(freebet)

const { getTitle } = useGetTitleByLevel()

const title = computed(() => {
  const titleByStatus =
    status.value === 'process' ? t('bonuses.betDone') : t('bonuses.freebet')

  return `${titleByStatus} ${getTitle(
    '',
    freebet.value.bonusForDepositProgram?.level,
  )}`
})

const { format } = useCryptoFormatter()
const freebetAmount = computed(() =>
  status.value === 'rolling'
    ? format(freebet.value.payoff ?? 0)
    : format(freebet.value.amount),
)

const stepRollingData = computed(() => ({
  stepRollingData: freebet.value.rollingSteps ?? [],
  rollingData: freebet.value.rolling,
  bonusAmount: freebet.value.payoff ?? '0',
}))
const {
  unlockedAmount,
  untilNextClaimAmount,
  partClaim,
  partClaimStatus,
  isEnabledStepsRolling,
  stepsProgress,
  canPartClaim,
} = useBonusStepRolling(stepRollingData)

async function handlePartClaim() {
  await partClaim()
  if (partClaimStatus.value === 'success') {
    await delay(300)
    await fetchFreebets()
  }
}

const isVisiblePartClime = computed(
  () => canPartClaim.value && !isEnabledStepsRolling.value,
)
const buttonSettings = computed(() => {
  if (isVisiblePartClime.value || status.value === 'rolled') {
    return {
      label: t('bonuses.take'),
      loading: partClaimStatus.value === 'pending',
      disabled: ['success', 'error'].includes(partClaimStatus.value),
      type: 'primary' as const,
      click: handlePartClaim,
    }
  }
  switch (status.value) {
    case 'new':
      return {
        label: t('bonuses.makeBet'),
        to: '/sport',
        type: 'ghost' as const,
      }
    case 'process':
      return {
        label: t('bonuses.openBets'),
        to: '/personal/bets',
        type: 'ghost' as const,
      }
    default:
      return null
  }
})

const bonusTimer = computed(() => {
  if (status.value === 'process') return null

  return {
    expiresAt: currentExpiredDate.value,
    title: timerTitle.value,
  }
})

const isVisibleSubTitle = computed(
  () =>
    ['new', 'process', 'rolled'].includes(status.value) ||
    isVisiblePartClime.value,
)
</script>

<style scoped>
.actions-wrapper {
  position: relative;
}

.animation {
  pointer-events: none;
  position: absolute;
  top: -12px;
  overflow: visible;
}

.wager {
  font: var(--mobile-caption-1-regular);
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .icon {
    margin: 0 var(--spacing-025);
  }
}

.subtitle {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;

  margin: 0;

  font: var(--mobile-caption-1-medium);
  color: var(--text-warning);
}

.rolling-min-max {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;
  font: var(--mobile-caption-1-regular);
}

.bonus-amount {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;

  margin-left: auto;

  font: var(--mobile-caption-1-medium);
  color: var(--text-warning);

  &.paused {
    margin-left: 0;
  }
}

.rolling-text {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
}

.rolling-text-amounts {
  overflow: hidden;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button-settings {
  white-space: nowrap;
}

.steps-amount {
  margin-left: auto;
  font: var(--mobile-caption-1-regular);
  color: var(--text-primary);
}

.steps-amount-info {
  overflow: hidden;
  display: flex;
  gap: var(--spacing-025);
  align-items: center;

  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
