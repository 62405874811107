<template>
  <BaseBonusPanel
    :id="freespin.userFreespinProgramId"
    type="freespin"
    :progress="progress"
    :steps-progress="stepsProgress"
    :paused="rollingStatus === 'paused'"
    class="freespin-panel"
    :class="status"
    :timer="bonusTimer"
    @click="handleBonusClick"
  >
    <template #title>
      <i18n-t
        v-if="isBettingProgress || isCalculating"
        keypath="bonuses.freespinForGame"
        class="title-text"
      >
        <template #game>
          <b class="game-name">{{ gameName }}</b>
        </template>
      </i18n-t>
      <span v-else class="title-text" data-t="title-text">
        {{ t('bonuses.freespins') }} {{ levelTitle }}
      </span>
    </template>
    <template #wager>
      <div
        v-if="isEnabledStepsRolling && !isVisibleActions"
        class="steps-amount"
      >
        <div v-if="unlockedAmount" class="steps-amount-info">
          {{ t('bonuses.unlockedAmount') }}
          {{ formatFiat(unlockedAmount) }}
        </div>
        <div v-else class="steps-amount-info">
          {{ t('bonuses.untilNextClaim') }}
          {{ formatFiat(untilNextClaimAmount) }}
        </div>
      </div>
      <span
        v-else-if="status === 'rolling' && isRollingStatusInProgress"
        class="wager"
      >
        {{ t('bonuses.wager', { multiplier: wager }) }}
      </span>
    </template>
    <template #subtitle>
      <template v-if="status === 'inProgress'">
        <div
          v-if="freespin.freespinNumberLeft"
          class="rolling-data"
          data-t="rolling-data"
        >
          <div class="rolling-left">
            <p class="subtitle">
              {{ t('bonuses.left') }}
            </p>
            {{ progressSubtitle }}
          </div>
          <div class="rolling-right">
            <span class="bonus-amount" data-t="bonus-amount">
              <img :src="giftImage" alt="gift" width="20" />
              <span>
                {{ currentRewardAmount }}
              </span>
            </span>
          </div>
        </div>

        <p v-else class="subtitle">
          {{ t('bonuses.resultHandlingTitle') }}
        </p>
      </template>
      <p v-else-if="subtitleByStatus" class="new-subtitle" data-t="subtitle">
        {{ subtitleByStatus }}
      </p>
    </template>
    <template #minmax>
      <div v-if="status === 'rolling'" class="rolling-min-max">
        <span v-if="isRollingStatusInProgress" data-t="rolling-min-max">
          {{ minMaxRollingText }}
        </span>

        <span
          class="bonus-amount"
          :class="{ paused: isRollingStatusNewOrPaused }"
          data-t="bonus-amount"
        >
          <img
            v-if="!isRollingStatusNewOrPaused"
            :src="giftImage"
            alt="gift"
            width="20"
          />
          <span>
            {{ currentRewardAmount }}
          </span>
        </span>
      </div>
    </template>
    <template #actions>
      <div v-if="isVisibleActions" class="actions-wrapper">
        <StButton
          v-if="isVisiblePartClime"
          class="button"
          type="primary"
          :loading="partClaimStatus === 'pending'"
          :disabled="['success', 'error'].includes(partClaimStatus)"
          :label="t('bonuses.take')"
          data-t="part-rolling-button"
          @click="handlePartClaim"
        />
        <StButton
          v-else-if="isRollingStatusNewOrPaused"
          size="m"
          type="secondary"
          :label="t('bonuses.toRoll')"
          @click="onSubmitClick"
        />
        <StButton
          v-else-if="status === 'new'"
          :label="t('bonuses.chooseGame')"
          size="m"
          @click="onSubmitClick"
        />
        <ClientOnly>
          <StLottie
            v-if="partClaimStatus === 'success'"
            class="animation"
            :animation-data="claimAnimation"
          />
        </ClientOnly>
      </div>
      <StSpinner
        v-if="isCalculating"
        size="24"
        stroke-width="4"
        data-t="spiner"
      />
    </template>
  </BaseBonusPanel>
</template>

<script setup lang="ts">
import { delay } from '@st/utils'
import BaseBonusPanel from './BaseBonusPanel.vue'
import type { Freespin } from '../../../types'
import { useFreespin } from '../../../composables/useFreespin'
import { useGetTitleByLevel } from '../../../composables/useGetTitleByLevel'
import giftImage from '../assets/gift.png'
import { useBonusStepRolling } from '../../../composables/useBonusStepRolling'
import claimAnimation from '../../../assets/claim-animation.json'

interface Props {
  freespin: Freespin
}

const props = defineProps<Props>()

const { t } = useI18n()
const { freespin } = toRefs(props)

const {
  status,
  onSubmitClick,
  wager,
  bettingProgress,
  rollingProgress,
  currentRewardAmount,
  currentRollingAmount,
  fullRollingAmount,
  gameName,
  isRollingStatusNewOrPaused,
  isRollingStatusInProgress,
  isProccessingLastFreespin,
  freespinBetAmount,
  rollingStatus,
  formatFiat,
  fetchFreespins,
} = useFreespin(freespin)

const { getTitle } = useGetTitleByLevel()

const levelTitle = computed(() =>
  getTitle('', freespin.value.bonusForDepositProgram?.level),
)

const isCalculating = computed(
  () => status.value === 'inProgress' && !props.freespin.freespinNumberLeft,
)
const isBettingProgress = computed(
  () => status.value === 'inProgress' && props.freespin.freespinNumberLeft,
)
const progressSubtitle = computed(
  () =>
    `${freespin.value.freespinNumberLeft} / ${freespin.value.freespinNumber} FS`,
)

const subtitleByStatus = computed(() => {
  if (status.value === 'new') {
    return `${freespin.value.freespinNumber} FS × ${freespinBetAmount.value}`
  }
  if (status.value === 'processed' || rollingStatus.value === 'rolled')
    return currentRewardAmount.value

  return ''
})

const progress = computed(() => {
  if (rollingStatus.value === 'rolled') return ''

  switch (status.value) {
    case 'rolling':
      return rollingProgress.value
    case 'inProgress':
      return bettingProgress.value
    default:
      return ''
  }
})

const minMaxRollingText = computed(
  () => `${currentRollingAmount.value} / ${fullRollingAmount.value}`,
)

const bonusTimer = computed(() => {
  if (isProccessingLastFreespin.value) return null

  return {
    expiresAt: freespin.value.expiredAt,
    title: t('bonuses.timerTitle'),
  }
})

const stepRollingData = computed(() => ({
  stepRollingData: freespin.value.rollingSteps ?? [],
  rollingData: freespin.value.rolling,
  bonusAmount: freespin.value.freespinCurrentRewardAmount ?? '0',
}))
const {
  unlockedAmount,
  untilNextClaimAmount,
  partClaim,
  partClaimStatus,
  isEnabledStepsRolling,
  canPartClaim,
  stepsProgress,
} = useBonusStepRolling(stepRollingData)

async function handlePartClaim() {
  await partClaim()
  if (partClaimStatus.value === 'success') {
    await delay(300)
    await fetchFreespins()
  }
}

const isVisiblePartClime = computed(
  () =>
    (canPartClaim.value && !isEnabledStepsRolling.value) ||
    rollingStatus.value === 'rolled',
)

const isVisibleActions = computed(
  () =>
    isVisiblePartClime.value ||
    status.value === 'new' ||
    isRollingStatusNewOrPaused.value,
)

function handleBonusClick() {
  if (
    status.value === 'new' ||
    isCalculating.value ||
    isRollingStatusNewOrPaused.value
  )
    return

  onSubmitClick()
}
</script>

<style scoped>
.actions-wrapper {
  position: relative;
}

.wager {
  min-width: 80px;
  font: var(--mobile-caption-1-regular);
  text-align: right;
}

.subtitle {
  margin: 0;
  font: var(--mobile-caption-1-medium);
  color: var(--text-secondary);

  span {
    color: var(--text-primary);
  }
}

.new-subtitle {
  margin: 0;
  font: var(--mobile-caption-1-medium);
  color: var(--text-warning);
}

.rolling-min-max {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;
  font: var(--mobile-caption-1-regular);
}

.rolling-data {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;
  justify-content: space-between;

  font: var(--mobile-caption-1-regular);
  color: var(--text-primary);
}

.rolling-left {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;
}

.bonus-amount {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  margin-left: auto;

  font: var(--mobile-caption-1-medium);
  color: var(--text-warning);

  &.paused {
    margin-left: 0;
    opacity: 0.4;
  }
}

.freespin-panel {
  &:not(.new) {
    cursor: pointer;
  }
}

.game-name {
  font: var(--mobile-text-semibold);
  color: var(--text-link);
}

.title-text {
  overflow: hidden;
  display: block;
  max-width: 200px;
  text-overflow: ellipsis;
}

.animation {
  pointer-events: none;
  position: absolute;
  top: -12px;
  overflow: visible;
}

.steps-amount {
  margin-left: auto;
  font: var(--mobile-caption-1-regular);
  color: var(--text-primary);
}

.steps-amount-info {
  overflow: hidden;
  display: flex;
  gap: var(--spacing-025);
  align-items: center;

  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
