<template>
  <BaseBonusPanel
    :id="bonus.id"
    type="cash"
    :progress="rollingProgress"
    :steps-progress="stepsProgress"
    :paused="isRollingStatusNewOrPaused"
    :timer="bonusTimer"
  >
    <template v-if="status === 'done'" #image>
      <img :src="depositClaim" alt="" width="44" height="44" />
    </template>
    <template #title> {{ titleByLevel }} </template>
    <template #wager>
      <div v-if="isEnabledStepsRolling" class="steps-amount">
        <div
          v-if="unlockedAmount"
          class="steps-amount-info"
          data-t="unlocked-amount"
        >
          {{ t('bonuses.unlockedAmount') }}
          {{ format(unlockedAmount) }}
          <StIcon :name="bonusCurrencyIcon" :size="14" />
        </div>
        <div v-else class="steps-amount-info" data-t="until-next-claim">
          {{ t('bonuses.untilNextClaim') }}
          {{ format(untilNextClaimAmount) }}
          <StIcon :name="bonusCurrencyIcon" :size="14" />
        </div>
      </div>
      <span v-else-if="isRolling && isRollingStatusInProgress" class="wager">
        {{ t('bonuses.wager', { multiplier: rollingMultiplier }) }}
      </span>
    </template>
    <template #minmax>
      <div v-if="isRolling" class="rolling-min-max">
        <template v-if="isRollingStatusInProgress">
          <span class="rolling-min-max-value" :title="minMaxRollingText">
            {{ minMaxRollingText }}
          </span>
          <StIcon
            v-if="bonusCurrencyIcon"
            :name="bonusCurrencyIcon"
            :size="14"
          />
        </template>
        <span
          class="bonus-amount"
          :class="{ paused: isRollingStatusNewOrPaused }"
        >
          <img :src="giftImage" alt="gift" width="20" />
          <span>
            {{ bonusAmountForClaim }}
          </span>
          <StIcon
            v-if="bonusCurrencyIcon"
            :name="bonusCurrencyIcon"
            :size="14"
          />
        </span>
      </div>
    </template>
    <template #subtitle>
      <p v-if="isCanClaimed" class="bonus-text claim">
        {{ bonusAmountForClaim }}
        <StIcon v-if="bonusCurrencyIcon" :name="bonusCurrencyIcon" :size="14" />
      </p>
    </template>
    <template #actions>
      <StButton
        v-if="isRollingStatusNewOrPaused"
        size="m"
        type="secondary"
        :label="t('bonuses.toRoll')"
        data-t="to-roll"
        @click="setCurrentRolling"
      />
      <div
        v-else-if="isCanClaimed || canPartClaimForDepositBonus"
        class="claim-box"
      >
        <StButton
          v-if="canPartClaimForDepositBonus"
          :label="t('bonuses.take')"
          size="m"
          :loading="partClaimStatus === 'pending'"
          :disabled="['success', 'error'].includes(partClaimStatus)"
          data-t="part-claim"
          @click="handlePartClaim"
        />
        <StButton
          v-else-if="isCanClaimed"
          :label="t('bonuses.take')"
          size="m"
          :loading="claimStatus === 'pending'"
          :disabled="['success', 'error'].includes(claimStatus)"
          data-t="full-claim"
          @click="handleBonusClaim"
        />

        <ClientOnly>
          <StLottie
            v-if="isSuccessBonusClaimStatus"
            class="animation"
            :animation-data="claimAnimation"
          />
        </ClientOnly>
      </div>
    </template>
  </BaseBonusPanel>
</template>

<script setup lang="ts">
import { delay } from '@st/utils'
import BaseBonusPanel from './BaseBonusPanel.vue'
import { useCashBonus } from '../../../composables/useCashBonus'
import { useBonusRolling } from '../../../composables/useBonusRolling'
import { useBonusStepRolling } from '../../../composables/useBonusStepRolling'
import type { CashBonus } from '../../../types'
import giftImage from '../assets/gift.png'
import claimAnimation from '../../../assets/claim-animation.json'
import depositClaim from '../../../assets/deposit-claim.png'

const props = defineProps<{
  bonus: CashBonus
}>()

const { t } = useI18n()

const { bonus } = toRefs(props)

const {
  isRollingStatusNewOrPaused,
  isRollingStatusInProgress,
  currentRollingAmount,
  fullRollingAmount,
  setCurrentRolling,
  rollingProgress,
} = useBonusRolling(computed(() => bonus.value.rolling))

const {
  isRolling,
  isCanClaimed,
  claimStatus,
  bonusCurrencyIcon,
  bonusAmountForClaim,
  rollingMultiplier,
  titleByLevel,
  handleBonusClaim,
  updateCashBonuses,
  bonusTimer,
  status,
} = useCashBonus(bonus)

const minMaxRollingText = computed(
  () => `${currentRollingAmount.value} / ${fullRollingAmount.value}`,
)

const stepRollingData = computed(() => ({
  stepRollingData: bonus.value.rollingSteps ?? [],
  rollingData: bonus.value.rolling,
  bonusAmount: bonus.value.amount,
}))

const {
  unlockedAmount,
  untilNextClaimAmount,
  canPartClaim,
  partClaim,
  partClaimStatus,
  isEnabledStepsRolling,
  stepsProgress,
} = useBonusStepRolling(stepRollingData)

const canPartClaimForDepositBonus = computed(() => {
  // если бонус без обкатки ( необходимо выполнить claim всего бонуса целиком)
  if (!bonus.value.rolling) return false

  return canPartClaim.value && !isEnabledStepsRolling.value
})

const isSuccessBonusClaimStatus = computed(
  () => claimStatus.value === 'success' || partClaimStatus.value === 'success',
)

async function handlePartClaim() {
  await partClaim()
  if (partClaimStatus.value === 'success') {
    await delay(300)
    await updateCashBonuses()
  }
}

const { format } = useCryptoFormatter()
</script>

<style scoped>
.bonus-text {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;

  margin: 0;

  font: var(--mobile-caption-1-medium);
  color: var(--text-warning);
}

.rolling-min-max {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;
  font: var(--mobile-caption-1-regular);
}

.rolling-min-max-value {
  overflow: hidden;
  max-width: 145px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.wager {
  font: var(--mobile-caption-1-regular);
}

.bonus-amount {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;

  margin-left: auto;

  font: var(--mobile-caption-1-medium);
  color: var(--text-warning);

  &.paused {
    margin-left: 0;
    opacity: 0.4;
  }
}

.claim-box {
  position: relative;
}

.animation {
  position: absolute;
  top: -12px;
  overflow: visible;
}

.sub-bonus {
  overflow: hidden;
  display: flex;
  align-items: center;

  .icon {
    min-width: 14px;
    margin: 0 var(--spacing-025);
  }
}

.steps-amount {
  margin-left: auto;
  font: var(--mobile-caption-1-regular);
  color: var(--text-primary);
}

.steps-amount-info {
  overflow: hidden;
  display: flex;
  gap: var(--spacing-025);
  align-items: center;

  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
