<template>
  <article class="bonus-panel" :class="platform" :data-t="`${type}-panel`">
    <header class="bonus-header">
      <slot name="image">
        <img
          v-if="imageByType"
          :src="imageByType"
          alt="bonus"
          :width="44"
          :height="44"
          class="bonus-image"
          :class="{ paused }"
        />
      </slot>
      <StIcon
        v-if="paused"
        class="pause-icon"
        :size="16"
        name="pause-bold-solid"
      />
      <div class="content">
        <header class="header">
          <h4 class="title" data-t="title">
            <slot name="title"></slot>
          </h4>
          <slot name="wager"></slot>
        </header>
        <div v-if="isStepProgressVisible" class="steps-progress-wrapper">
          <StepsProgress v-if="!paused" :steps-progress="stepsProgress" />
          <slot name="minmax"></slot>
        </div>
        <div
          v-else-if="isProgressVisible"
          class="rolling-progress"
          data-t="rolling-progress"
        >
          <div v-if="!paused" class="progress-bar"></div>
          <slot name="minmax"></slot>
        </div>
        <slot name="subtitle"></slot>
      </div>
      <slot name="actions"></slot>
    </header>
    <div class="separator" />
    <footer v-if="isFooterVisible" class="bonus-footer">
      <button
        v-if="tooltipTitle"
        class="tooltip-button"
        data-t="tooltip-button"
        :data-t-tooltip-name="tooltipName"
        @click.stop="handleShowTooltip"
      >
        <span>{{ tooltipTitle }}</span>
        <StIcon class="info-icon" name="circle-info-solid" :size="16" />
      </button>
      <StTimer
        v-if="timer"
        :expires-at="timer.expiresAt"
        :label="timer.title"
        direction="row"
        with-days
        :max-days="30"
        class="timer"
      />
    </footer>
  </article>
</template>

<script setup lang="ts">
import {
  submodalDataInjectionKey,
  useModalBonusTooltip,
  type SubmodalProvidedData,
} from '../../../composables/useBonusTooltip'
import freebetImage from '../../../assets/freebet.png'
import freespinImage from '../../../assets/freespins.png'
import reloadImage from '../../../assets/reload.png'
import rakebackImage from '../../../assets/rakeback.png'
import depositImage from '../../../assets/deposit.png'
import cashbackImage from '../../../assets/cashback.png'
import tournamentImg from '../../../assets/tournament.png'
import type { StepRolling } from '../../../types'

interface Props {
  id: number | string
  type:
    | 'deposit'
    | 'cash'
    | 'freespin'
    | 'freebet'
    | 'cashback'
    | 'rakeBack'
    | 'reload'
    | 'ratRace'
    | 'vip'
  progress?: string
  stepsProgress?: {
    steps: StepRolling[]
    inProgressStepProgress: string
  }
  paused?: boolean | null
  timer?: {
    expiresAt: string
    title?: string
  } | null
}

const { platform, isDesktop } = usePlatform()
const {
  type,
  id,
  progress,
  timer,
  stepsProgress = {
    steps: [],
    inProgressStepProgress: '0',
  },
} = defineProps<Props>()

const imageByType = computed(
  () =>
    ({
      deposit: depositImage,
      cash: depositImage,
      freespin: freespinImage,
      freebet: freebetImage,
      reload: reloadImage,
      cashback: cashbackImage,
      rakeBack: rakebackImage,
      ratRace: tournamentImg,
      vip: null,
    })[type],
)

const isProgressVisible = computed(() => progress && progress !== '100')
const isStepProgressVisible = computed(() => stepsProgress.steps.length > 1)
const progressPercents = computed(() => `${progress}%`)

const submodalData = inject<SubmodalProvidedData>(
  submodalDataInjectionKey,
) as SubmodalProvidedData

const {
  showTooltip: showModalTooltip,
  tooltipTitle,
  tooltipName,
} = useModalBonusTooltip(computed(() => ({ id, type })))

const isFooterVisible = computed(() => !!tooltipTitle || !!timer)

function handleShowTooltip() {
  if (isDesktop.value || !tooltipName.value) {
    showModalTooltip()
    return
  }
  submodalData.showTooltip({
    modalName: tooltipName.value,
    bonusId: id,
  })
}
</script>

<style scoped>
.bonus-panel {
  position: relative;

  overflow: hidden;
  display: flex;
  flex-direction: column;

  min-width: 328px;
  padding: var(--spacing-075) var(--spacing-125);

  background: var(--background-secondary);
  border-radius: var(--border-radius-150);

  &:hover {
    background: var(--background-tertiary);
  }

  &.mobile {
    background: var(--background-primary);
  }
}

.bonus-header {
  display: flex;
  gap: var(--spacing-125);
  align-items: center;

  min-height: 50px;
  margin-bottom: var(--spacing-100);
}

.separator {
  width: 100%;
  height: 1px;
  background-color: var(--border-primary);
}

.bonus-footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: var(--spacing-100);
}

.bonus-image {
  min-width: 44px;
}

.paused {
  opacity: 0.4;
  filter: blur(1px);
}

.pause-icon {
  position: absolute;
  top: 20px;
  left: 24px;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-050);

  min-width: 0;
}

.title {
  overflow: hidden;

  margin: 0;

  font: var(--mobile-text-semibold);
  color: var(--text-primary);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subtitle {
  margin: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font: var(--mobile-text-semibold);
}

.rolling-progress {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
}

.progress-bar {
  position: relative;

  height: 6px;

  background: url('../assets/progress-bg.svg') var(--border-primary);
  background-size: contain;
  border-radius: var(--border-radius-100);

  &::after {
    content: '';

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: v-bind(progressPercents);

    background-color: #3ca251; /* stylelint-disable-line */
    border-radius: var(--border-radius-100);
  }
}

.tooltip-button {
  all: unset;

  cursor: help;

  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  font: var(--desktop-text-xs-semibold);
  color: var(--text-tertiary);

  &:hover {
    color: var(--text-secondary);
  }
}

.timer {
  margin-left: auto;
}
</style>
